import { captureMessage } from '@sentry/react'

export default class SentryTwilioVoiceLogger {
  private _loggerOriginal = console.log
  private _deviceLogs: string[] = []
  private _intervalHandle: number | null = null
  private _state: 'started' | 'stopped' = 'stopped'

  constructor(private readonly logInterval = 3000) {}

  init = () => {
    console.log = this.log
    return this
  }

  log = (...args: unknown[]) => {
    this._loggerOriginal.apply(console, args)
    const logMessage = args.join(' ')
    if (logMessage.includes('[TwilioVoice]')) {
      this._deviceLogs.push(logMessage)
    }
  }

  start = () => {
    if (this._state === 'started') {
      return
    }
    this._state = 'started'
    this._intervalHandle = window.setInterval(() => {
      if (this._deviceLogs.length > 0) {
        captureMessage('Twilio Voice Debug Logs', {
          level: 'error',
          tags: {
            callDebugging: true,
          },
          extra: {
            twilioLogs: this._deviceLogs,
          },
        })
        this._deviceLogs = []
      }
    }, this.logInterval)
  }

  revert = () => {
    if (this._state === 'stopped') {
      return
    }
    console.log = this._loggerOriginal.bind(console)
    if (this._intervalHandle) {
      clearInterval(this._intervalHandle)
      this._intervalHandle = null
    }
    this._state = 'stopped'
  }

  get deviceLogs() {
    return this._deviceLogs
  }
}
