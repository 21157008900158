import cx from 'classnames'
import type { ReactNode } from 'react'

import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import Tooltip, { type TooltipProps } from '@ui/Tooltip'

import * as styles from './TextWrap.css'

export interface TextWrapProps {
  value: string
  children: ReactNode
  className?: string
  tooltipProps?: Pick<TooltipProps, 'placement' | 'align' | 'offset'>
}

const TextWrap = ({ value, children, className, tooltipProps }: TextWrapProps) => {
  const { isOverflowing, ref } = useIsOverflowing<HTMLDivElement>()
  return (
    <Tooltip
      title={value}
      disabled={!isOverflowing}
      placement={tooltipProps?.placement || 'top'}
      align={tooltipProps?.align}
      offset={tooltipProps?.offset}
      allowTextSelection
    >
      <div className={cx(styles.root, className)} ref={ref}>
        {children}
      </div>
    </Tooltip>
  )
}

export default TextWrap
