import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import IconButton from '@src/component/IconButton'
import FileIcon from '@src/component/file-icon'
import { fileType } from '@src/lib/file'
import useKeyboardShortcuts from '@src/lib/hooks/useKeyboardShortcuts'
import type { MessageMediaModel } from '@src/service/model'
import Modal from '@ui/Modal'
import { ArrowLeftIcon, ArrowRightIcon } from '@ui/icons/tint/20/general'
import { useTheme } from '@ui/theme'

import MediaPreview from './MediaPreview'
import * as styles from './MediaViewer.css'

export interface MediaViewerState {
  media: MessageMediaModel[]
  index: number
}

export interface MediaViewerProps {
  closeMediaViewer(): void
  mediaViewer: MediaViewerState
}

const MediaViewer = function ({ closeMediaViewer, mediaViewer }: MediaViewerProps) {
  const theme = useTheme()
  const selectedIndex = mediaViewer.index
  const media = mediaViewer.media
  const m = media[mediaViewer.index]
  const mediaType = fileType(m?.type, m?.url || m?.name)

  useEffect(() => {
    const root = document.getElementById('root')

    if (!root) {
      return
    }

    root.classList.add(styles.faded)
    return () => root.classList.remove(styles.faded)
  }, [])

  useKeyboardShortcuts({
    name: 'conversation/media-viewer',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'ArrowLeft' && mediaViewer.index > 0) {
        handlePrevious()
        event.preventDefault()
      } else if (
        shortcut === 'ArrowRight' &&
        mediaViewer.index < mediaViewer.media.length - 1
      ) {
        handleNext()
        event.preventDefault()
      } else if (shortcut === 'Escape') {
        closeMediaViewer()
        event.preventDefault()
      }
    },
  })

  const handleDownload = function () {
    if (m?.url) {
      window.open(m.url, '_blank')
    }
  }

  const handleClose = () => {
    closeMediaViewer()
  }

  const handleNext = function () {
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    mediaViewer.index += 1
  }

  const handlePrevious = function () {
    mediaViewer.index -= 1
  }

  const hasPrevious = mediaViewer.index > 0
  const hasNext = mediaViewer.index < mediaViewer.media.length - 1

  const rootClass = theme.match({
    dark: styles.rootDark,
    light: styles.rootLight,
  })
  const buttonClass = theme.match({
    dark: styles.buttonDark,
    light: styles.buttonLight,
  })

  if (!m) {
    closeMediaViewer()
    return null
  }

  return (
    <Modal
      onClose={handleClose}
      className={rootClass}
      underlayColor="rgba(0, 0, 0, 0.5)"
      isDismissable={true}
      aria-label={`View file: ${m.displayName}`}
    >
      <div className={rootClass}>
        <header className={styles.header}>
          <div className={styles.headerSection}>
            <FileIcon type={mediaType} />
            <div style={{ marginLeft: 15 }}>{m?.displayName}</div>
          </div>
          <div className={styles.pageNumber}>
            {selectedIndex + 1}/{media.length}
          </div>
          <div className={styles.headerSection} style={{ justifyContent: 'flex-end' }}>
            <IconButton
              className={buttonClass({ buttonType: 'action' })}
              size={30}
              color="default"
              onClick={handleDownload}
              title="Launch in new tab"
              icon={<LaunchIcon />}
            />
            <IconButton
              className={buttonClass({ buttonType: 'action' })}
              size={30}
              onClick={closeMediaViewer}
              title="Close"
              icon={<CloseIcon />}
            />
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.closeOnClick} onClick={handleClose} />
          <MediaPreview media={m} />
        </div>

        {hasPrevious ? (
          <div className={cx(styles.navContainer, styles.prev)}>
            <IconButton
              className={buttonClass({ buttonType: 'nav' })}
              title="Previous"
              shortcut="←"
              onClick={handlePrevious}
              icon={<ArrowLeftIcon />}
            />
          </div>
        ) : null}

        {hasNext ? (
          <div className={cx(styles.navContainer, styles.next)}>
            <IconButton
              className={buttonClass({ buttonType: 'nav' })}
              title="Next"
              shortcut="→"
              onClick={handleNext}
              icon={<ArrowRightIcon />}
            />
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default observer(MediaViewer)
