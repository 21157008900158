/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { Method } from 'axios'

import config from '@src/config'
import type { IntegrationParagonTokenResponse } from '@src/service/dto/response/IntegrationParagonTokenResponse'
import type {
  CodableContact,
  IIntegrationContact,
  Integration,
  LegacyIntegration,
} from '@src/service/model'
import type CallDeposition from '@src/service/model/integration/CallDeposition'
import type ContactImport from '@src/service/model/integration/ContactImport'
import type CreatedBy from '@src/service/model/integration/CreatedBy'
import type { CodableSalesforceIntegration } from '@src/service/model/integration/SalesforceIntegrationModel'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface IIntegrationBase {
  id: string
  type: string
  orgId: string
  appId: string
  userId: string
  createdAt: Date
  updatedAt: Date
  createdBy: CreatedBy | null
}

export interface IHubspotIntegration extends IIntegrationBase {
  type: 'hubspot'
  hubspotUser: string
  resourceIds: string[]
  refreshToken: string
  accessToken: string
  accessTokenExpiresAt: Date
  contactProperties: string[]
  callDepositions: CallDeposition[]
  contactImport: ContactImport
}

export interface ISalesforceIntegration extends IIntegrationBase {
  type: 'salesforce'
  salesforceUser: string
  resourceIds: string[]
  refreshToken: string
  accessToken: string
  accessTokenExpiresAt: Date
  contactProperties: string[]
  callDepositions: CallDeposition[]
  contactImport: ContactImport
}

export interface IGongIntegration extends IIntegrationBase {
  accessToken: string
  accessTokenExpiresAt: Date
  gongUser?: string
  refreshToken: string
  resourceIds: string[]
  scope: string
  sendCallRecordings: { enabled: boolean }
  tokenType: string
  triggers: string[]
  type: 'gong'
  workspaceId: string | null
}

export interface IWebhookIntegration extends IIntegrationBase {
  resourceIds: string[]
  events: string[]
  method: Method
  type: 'webhook'
  url: string
}

export type IIntegration =
  | IHubspotIntegration
  | CodableSalesforceIntegration
  | IGongIntegration
  | IWebhookIntegration

export interface IntegrationEvent {
  incomingMessage: boolean
  answeredIncomingCall: boolean
  missedCallWithoutVoicemail: boolean
  missedCallWithVoicemail: boolean
}

export type IntegrationCreateParams = {
  code: string
  redirectUri: string
  resourceIds: string[]
}

export interface CreateSlackAuthParams {
  code: string
  phoneNumberId: string
  events: IntegrationEvent
  redirectUri: string
}

export interface IntegrationStatus {
  integrationId: string
  count: number
  status?: 'None' | 'Active' | 'Failed' | 'Succeeded'
  error?: string
}

export default class IntegrationClient {
  constructor(private transport: Transport) {}

  getAll(): Promise<Integration[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.INTEGRATION_SERVICE_URL}integration`,
      }),
    )
  }

  getForPhoneNumber(phoneNumberId: string): Promise<IIntegration[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.COMMUNICATION_SERVICE_URL}integration/settings/${phoneNumberId}`,
      }),
    )
  }

  update(integration: Partial<Integration>): Promise<Integration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.INTEGRATION_SERVICE_URL}integration`,
        body: integration,
      }),
    )
  }

  createHubspot(body: IntegrationCreateParams): Promise<IIntegration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.INTEGRATION_SERVICE_URL}provider/hubspot`,
        body,
        retry: false,
      }),
    )
  }

  createSalesforce(body: IntegrationCreateParams): Promise<IIntegration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.INTEGRATION_SERVICE_URL}provider/salesforce`,
        body,
        retry: false,
      }),
    )
  }

  createGong(body: IntegrationCreateParams): Promise<IIntegration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.INTEGRATION_SERVICE_URL}provider/gong`,
        body,
        retry: false,
      }),
    )
  }

  delete(id: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.INTEGRATION_SERVICE_URL}integration/${id}`,
      }),
    )
  }

  status(id: string): Promise<IntegrationStatus> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'get',
        url: `${config.INTEGRATION_SERVICE_URL}integration/${id}/status`,
      }),
    )
  }

  person = {
    get: (
      identifiers: string[],
      integrationIds: string[],
    ): Promise<IIntegrationContact[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.INTEGRATION_SERVICE_URL}person`,
          query: {
            identifiers: identifiers.join(','),
            integrationIds: integrationIds.join(','),
            enrichment: 'true',
          },
        }),
      )
    },

    create: (
      integration: Integration,
      contact: CodableContact,
    ): Promise<IIntegrationContact> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.INTEGRATION_SERVICE_URL}integration/${integration.id}/person`,
          body: contact,
        }),
      )
    },
  }

  legacy = {
    get: (phoneNumberId: string): Promise<LegacyIntegration[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${phoneNumberId}`,
        }),
      )
    },

    createSlack: (params: CreateSlackAuthParams): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/slack`,
          body: params,
        }),
      )
    },

    createEmail: (params: Partial<LegacyIntegration>): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/email`,
          body: params,
        }),
      )
    },

    update: ({
      id,
      ...settings
    }: Partial<LegacyIntegration>): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${id}`,
          body: settings,
        }),
      )
    },

    delete: (settings: Partial<LegacyIntegration>): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${settings.id}`,
        }),
      )
    },
  }

  paragon = {
    token: (): Promise<IntegrationParagonTokenResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'POST',
          url: `${config.INTEGRATION_SERVICE_URL}paragon/token`,
        }),
      )
    },
    sync: (): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'POST',
          url: `${config.INTEGRATION_SERVICE_URL}paragon/sync`,
        }),
      )
    },
  }
}
