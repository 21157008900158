import { makeAutoObservable } from 'mobx'

import type Transport from '@src/service/transport'

export default class AutomationsStore {
  constructor(private transport: Transport) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  getResources() {
    return this.transport.ai.automations.getResources()
  }

  getResourceById(id: string) {
    return this.transport.ai.automations.getResourceById(id)
  }

  createResourcePage(title: string, content: string) {
    return this.transport.ai.automations.createResourcePage(title, content)
  }

  updateResourcePage(id: string, title: string, content: string) {
    return this.transport.ai.automations.updateResourcePage(id, title, content)
  }

  deleteResource(id: string) {
    return this.transport.ai.automations.deleteResource(id)
  }
}
