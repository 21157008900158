import type { WorkflowSharedTypes } from '@openphone/dexie-database/types'

import type { StepDefinitionId } from '@src/app/components/workflow/types'

export const isPhoneMenuConfigValue = (
  value: WorkflowSharedTypes.WorkflowConfigurationValue['value'] | null | undefined,
): value is WorkflowSharedTypes.PhoneMenuConfigValue => {
  if (value && typeof value === 'object' && value['variableKey'] === undefined) {
    return true
  }

  return false
}

export const isPhoneMenuDigit = (
  value: number | string,
): value is WorkflowSharedTypes.PhoneMenuDigit => {
  return Boolean(
    value === 'no-selection' ||
      value === 0 ||
      value === 1 ||
      value === 2 ||
      value === 3 ||
      value === 4 ||
      value === 5 ||
      value === 6 ||
      value === 7 ||
      value === 8 ||
      value === 9,
  )
}

export const getPhoneMenuDestinationByNodeData = (
  stepDefinitionId: StepDefinitionId,
  configuration: WorkflowSharedTypes.WorkflowConfigurationValue[],
  phoneNumberId: string,
): string => {
  let value: WorkflowSharedTypes.WorkflowConfigurationValue['value'] | undefined =
    undefined

  if (stepDefinitionId === 'WSDgoTo') {
    value = 'repeat'
  }

  if (stepDefinitionId === 'WSDforwardCall') {
    value = configuration.find((entry) => entry.variableKey === 'WVforwardeePhoneNumber')
      ?.value
  }

  if (stepDefinitionId === 'WSDvoicemail') {
    value = configuration.find((entry) => entry.variableKey === 'WVvoicemailUrl')?.value
  }

  if (stepDefinitionId === 'WSDringUsers') {
    value =
      configuration.find((entry) => entry.variableKey === 'WVspecificUserToDial')
        ?.value || phoneNumberId
  }

  if (stepDefinitionId === 'WSDplayAudio') {
    value = configuration.find((entry) => entry.variableKey === 'WVaudioFileUrl')?.value
  }

  if (stepDefinitionId === 'WSDplayAudioAndContinue') {
    value = configuration.find((entry) => entry.variableKey === 'WVaudioFileUrl')?.value
  }

  if (typeof value !== 'string' || !value) {
    return ''
  }

  return value
}
