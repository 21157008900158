import { makeAutoObservable } from 'mobx'

import type Service from '..'

import AutomationsStore from './AutomationsStore'

export default class AiStore {
  automations: AutomationsStore
  constructor(private root: Service) {
    this.automations = new AutomationsStore(root.transport)
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  togglePrompt(promptId: string, isActive: boolean) {
    return this.root.transport.ai.prompt.toggle(promptId, isActive)
  }

  getPromptSettings(phoneNumberId: string) {
    return this.root.transport.ai.prompt.get(phoneNumberId)
  }
}
