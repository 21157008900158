import { observer } from 'mobx-react-lite'
import { useLayoutEffect, useRef } from 'react'

import Input from '@src/app/contact/ItemEditor/components/Input'
import { List, ListItem } from '@src/app/contact/ItemEditor/components/List'
import { useBackdropClick, useEditor } from '@src/app/contact/ItemEditor/context'
import useInputState from '@src/lib/hooks/useInputState'
import useKeyStepper from '@src/lib/hooks/useKeyStepper'
import keyboard from '@src/lib/keyboard'
import Typography from '@ui/Typography'

export interface EditNameProps {
  defaultValue: string
  onSave: (name: string) => void
  onDelete: () => void
}

const options: ('Save' | 'Delete')[] = ['Save', 'Delete']

const EditName = function ({ defaultValue, onSave, onDelete }: EditNameProps) {
  const [, hide] = useEditor()
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [input, setInput] = useInputState(defaultValue)

  useLayoutEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    })
  }, [])

  const handleSave = () => {
    onSave(input)
    hide()
  }

  const handleDelete = () => {
    onDelete()
    hide()
  }

  useBackdropClick(handleSave, [input])

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  const { getItemProps, selectedIndex } = useKeyStepper({
    items: options,
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    node: rootRef.current ?? undefined,
    defaultSelectedIndex: 0,
    name: 'contact/editor/name',
    handleSelect: (item) => {
      if (item === 'Save') {
        handleSave()
      } else if (item == 'Delete') {
        handleDelete()
      }
    },
    deps: [input],
  })

  return (
    <div ref={rootRef}>
      <Input
        ref={inputRef}
        autoFocus
        type="text"
        value={input}
        placeholder="Enter a name"
        onChange={setInput}
        onKeyDown={keyboard.onEnterOrTab(handleSave)}
      />
      <List>
        {options.map((item, index) => (
          <ListItem
            key={item}
            isSelected={selectedIndex === index}
            {...getItemProps(index)}
          >
            <Typography variant="footnote" color="textPrimary">
              {item}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default observer(EditName)
