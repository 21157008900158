import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import { useAppStore } from '@src/app/context'
import { useOpenInboxOrDefault } from '@src/app/inbox/useOpenInbox'
import IconButton from '@src/component/IconButton'
import useAsyncCopyToClipboard from '@src/lib/hooks/useAsyncCopyToClipboard'
import isNonNull from '@src/lib/isNonNull'
import { formatted } from '@src/lib/phone-number'
import type { ContactItemType } from '@src/service/transport/contacts'
import {
  CallIcon,
  CopyIcon,
  EnvelopeIcon,
  MessageIcon,
  NewWindowIcon,
} from '@ui/icons/tint/16/general'

import { placeholders } from './custom-item'
import Item from './index'

interface ReadyOnlyItemProps {
  type: ContactItemType
  name: string
  value: string
  userId?: string | null
  disableActions?: boolean
}

const ReadyOnlyItem = function ({
  type,
  name,
  value,
  userId = null,
  disableActions = false,
}: ReadyOnlyItemProps) {
  const valueRef = useRef<HTMLDivElement>(null)
  const { inboxes, service } = useAppStore()
  const inbox = useOpenInboxOrDefault()
  const copyToClipboard = useAsyncCopyToClipboard()

  const messagingEnabled = service.capabilities.features.messagingEnabled

  const handleCall = (event) => {
    if (inboxes.selectedPhoneNumber) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      service.voice.startCall(inboxes.selectedPhoneNumber, [
        { number: value, userId, type: userId ? 'member' : 'contact' },
      ])
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    event.stopPropagation()
  }

  const handleMessage = (event: React.MouseEvent) => {
    inbox?.newConversation(value)
    event.stopPropagation()
  }

  const handleEmail = (event?: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    event?.stopPropagation()
    window.open(`mailto:${value}`)
  }

  const handleUrl = (event?: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    event?.stopPropagation()
    window.open(value)
  }

  const handleCopy = (event: React.MouseEvent) => {
    let val: string = value
    if (type === 'phone-number') {
      val = formatted(value)
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    copyToClipboard({
      copyString: val,
      successMessage: 'Copied to clipboard.',
    })
    event.stopPropagation()
  }

  const handleValueClick = () => {
    if (type === 'url') {
      handleUrl()
    } else if (type === 'email') {
      handleEmail()
    }
  }

  const renderActions = () => {
    const actions: React.ReactNode[] = []
    if (type === 'email') {
      actions.push(
        <IconButton
          key="email"
          title="Email"
          size={22}
          icon={<EnvelopeIcon />}
          onClick={handleEmail}
        />,
      )
    } else if (type === 'url') {
      actions.push(
        <IconButton
          key="link"
          size={22}
          title="Open in new window"
          icon={<NewWindowIcon />}
          onClick={handleUrl}
        />,
      )
    } else if (type === 'phone-number') {
      actions.push(
        <IconButton
          key="call"
          size={22}
          title="Call"
          icon={<CallIcon />}
          onClick={handleCall}
        />,

        <IconButton
          key="message"
          size={22}
          title={
            messagingEnabled
              ? 'Message'
              : 'Your plan currently does not support this feature'
          }
          icon={<MessageIcon />}
          onClick={handleMessage}
          disabled={!messagingEnabled}
        />,
      )
    }
    if (typeof value === 'string') {
      actions.push(
        <IconButton
          key="copy"
          title="Copy"
          size={22}
          icon={<CopyIcon />}
          onClick={handleCopy}
        />,
      )
    }
    return actions.filter(isNonNull)
  }

  const renderValue = () => {
    if (type === 'phone-number') {
      return formatted(value)
    } else if (type === 'company') {
      return value
    } else if (Object.keys(placeholders).includes(type)) {
      return value
    }
  }

  return (
    <Item
      type={type}
      name={name}
      valueRef={valueRef}
      disableName
      value={renderValue()}
      onValueClick={disableActions ? undefined : handleValueClick}
      actions={disableActions ? null : renderActions()}
    />
  )
}

export default observer(ReadyOnlyItem)
