import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import type { TemplateTagItemOption } from '@src/lib/search'
import Popover from '@ui/Popover'
import TextField from '@ui/TextField'

import * as styles from './EditTag.css'

interface RenameTagForm {
  name: string
}

interface EditTagProps {
  tag: TemplateTagItemOption
  options: TemplateTagItemOption[]
  moreMenuButtonRef: React.MutableRefObject<HTMLButtonElement | null>
  onSave: (tag: TemplateTagItemOption, newName: string) => void
  onClose: () => void
}

export default function EditTag({
  tag,
  options,
  moreMenuButtonRef,
  onSave,
  onClose,
}: EditTagProps) {
  const schema = useMemo(() => {
    return z
      .object({
        name: z
          .string()
          .trim()
          .nonempty("Tag can't be blank")
          .refine(
            (name) => {
              const otherTagNames = options
                .filter((option) => option.name !== tag.name)
                .map((option) => option.name)

              return !otherTagNames.includes(name)
            },
            {
              message: 'Tag with this name already exists',
            },
          ),
      })
      .required()
  }, [options, tag.name])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<RenameTagForm>({
    defaultValues: {
      name: tag.name,
    },
    resolver: zodResolver(schema),
    mode: 'all',
  })

  useEffect(() => {
    reset()
  }, [reset, tag])

  const handleTagRename = handleSubmit(({ name }) => {
    if (!tag || name === tag.name) {
      return
    }
    onSave(tag, name)
  })

  return (
    <Popover
      placement="bottom left"
      offset={6}
      isDismissable
      targetRef={moreMenuButtonRef}
      onClose={onClose}
      className={styles.root}
      autoFocus
    >
      <form onSubmit={handleTagRename}>
        <TextField
          size={35}
          {...register('name')}
          errorText={errors.name?.message}
          aria-invalid={!!errors.name}
          aria-errormessage={errors.name?.message}
        />
      </form>
    </Popover>
  )
}
