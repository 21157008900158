import type { Reducer } from 'react'
import { createContext, useContext, useEffect } from 'react'

import type {
  EditCompanyProps,
  EditDateProps,
  EditNameProps,
  EditPhoneNumberProps,
  EditStringItemProps,
  EditTagsProps,
} from '@src/app/contact/ItemEditor/components'
import type { PopoverProps } from '@ui/Popover'

type Command =
  | ({ name: 'edit name'; onDiscard?: () => void } & EditNameProps)
  | ({ name: 'edit company'; onDiscard?: () => void } & EditCompanyProps)
  | ({ name: 'edit phone number'; onDiscard?: () => void } & EditPhoneNumberProps)
  | ({ name: 'edit string'; onDiscard?: () => void } & EditStringItemProps)
  | ({ name: 'edit tags'; onDiscard?: () => void } & EditTagsProps)
  | ({ name: 'edit date'; clear?: boolean; onDiscard?: () => void } & EditDateProps)

type TargetRef = PopoverProps['targetRef']

type Action =
  | { type: 'show editor'; targetRef: TargetRef; command: Command }
  | { type: 'backdrop clicked' }
  | { type: 'hide editor' }

type Dispatch = React.Dispatch<Action>

type State =
  | {
      open: false
      backdropClicked: boolean
      targetRef: null
      command: null
    }
  | {
      open: true
      backdropClicked: boolean
      targetRef: TargetRef
      command: Command
    }

export const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'show editor':
      return {
        open: true,
        backdropClicked: false,
        targetRef: action.targetRef,
        command: action.command,
      }
    case 'backdrop clicked':
      return { ...state, backdropClicked: true }
    case 'hide editor':
      return { open: false, backdropClicked: false, targetRef: null, command: null }
    default:
      return state
  }
}

export const ContactEditorStateContext = createContext<State | undefined>(undefined)
export const ContactEditorDispatchContext = createContext<Dispatch | undefined>(undefined)

function useContactEditorDispatch(): Dispatch {
  const context = useContext(ContactEditorDispatchContext)
  if (!context) {
    throw new Error(
      '`useContactEditorDispatchStore` has to be used in a child of `ContactEditorDispatchContext.Provider`',
    )
  }
  return context
}

export function useEditor(): [
  (targetRef: TargetRef, command: Command) => void,
  () => void,
] {
  const dispatch = useContactEditorDispatch()

  const run = (targetRef: TargetRef, command: Command) =>
    dispatch({ type: 'show editor', targetRef, command })
  const close = () => dispatch({ type: 'hide editor' })

  return [run, close]
}

function useContactEditorState(): State {
  const context = useContext(ContactEditorStateContext)
  if (!context) {
    throw new Error(
      '`ContactEditorStateContext` has to be used in a child of `ContactEditorStateContext.Provider`',
    )
  }
  return context
}

export function useBackdropClick(handle: () => void, deps: any[]): void {
  const state = useContactEditorState()
  useEffect(() => {
    if (state.backdropClicked) {
      handle()
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  }, [handle, state.backdropClicked, ...deps])
}
