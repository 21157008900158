import cx from 'classnames'
import { forwardRef } from 'react'

import * as styles from './Input.css'

type InputProps = React.HTMLProps<HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  return <input ref={ref} className={cx(styles.root, className)} {...props} />
})

export default Input
