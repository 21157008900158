import dayjs from 'dayjs'
import type vCard from 'vcf'
import VCF from 'vcf'

import isNonNull from './isNonNull'

type VCardNamedItem = { name: string; value: string }
export interface VCard {
  fn: string | null
  birthday: string | null
  photo: string | null
  email: VCardNamedItem[] | null
  phone: VCardNamedItem[] | null
  org: string | null
  title: string | null
  role: string | null
  address: VCardNamedItem[] | null
  url: VCardNamedItem[] | null
}

export async function fromUrl(
  url: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<VCard | null> {
  try {
    const request = await fetch(url, { signal })
    const body = await request.text()
    const vcf = new VCF().parse(body)

    return convertVCard(vcf)
  } catch (error) {
    // ignore
  }

  return null
}

export async function fromFile(file: File): Promise<VCard> {
  const result = await file.text()

  return convertVCard(new VCF().parse(result))
}

type Item = VCF.Property & { type: string; encoding?: string }

function extractVCFPhoto(card: VCF): VCard['photo'] {
  const value = card.get('photo')

  if (!value) {
    return null
  }

  let property: Item | null = null

  if (Array.isArray(value)) {
    const preferredImage = value.find((item) => {
      if ('type' in item && (typeof item.type === 'string' || Array.isArray(item.type))) {
        return item.type.includes('pref')
      }
      return false
    }) as Item

    property = preferredImage || value[0] || null
  } else {
    property = value as Item
  }

  // if the image is embedded extract it
  if (property.encoding?.toLowerCase() === 'base64') {
    const mimeType =
      (Array.isArray(property.type) &&
        (property.type.find((item) => /^(jpe?g|png|gif|webp)$/.test(`${item}`)) as
          | string
          | undefined)) ||
      'jpg'
    return `data:image/${mimeType};base64,${property.valueOf()}`
  }

  return property.valueOf()
}

function convertToVCardItem(property: ReturnType<vCard['get']>): VCardNamedItem[] | null {
  const toItem = (
    item: VCF.Property & { type?: string | string[] },
  ): VCardNamedItem | null => {
    const value = item?.valueOf()

    if (!value) {
      return null
    }

    return {
      name: typeof item.type === 'string' ? item.type : item.type?.join(' ') ?? '',
      value,
    }
  }

  if (Array.isArray(property)) {
    return property.map(toItem).filter(isNonNull)
  }

  const singleItem = toItem(property)

  return singleItem ? [singleItem] : null
}

function extractVCFBirthday(property: ReturnType<vCard['get']>): VCard['birthday'] {
  if (!property) {
    return null
  }

  const item = Array.isArray(property) ? property[0] : property

  if (!item) {
    return null
  }

  // the value could be YYYYMMDD or YYMMDDHHmmss
  const possibleDates = [
    dayjs(item.valueOf(), 'YYYYMMDD'),
    dayjs(item.valueOf(), 'YYYYMMDDHHmmss'),
  ]

  return possibleDates.find((date) => date.isValid())?.toISOString() ?? null
}

function convertVCard(card: VCF): VCard {
  const fn = (card.get('fn')?.valueOf() as string) ?? null
  const birthday = extractVCFBirthday(card.get('bday'))
  const photo = extractVCFPhoto(card)
  const email = convertToVCardItem(card.get('email'))
  const phone = convertToVCardItem(card.get('tel'))
  const org = (card.get('org')?.valueOf() as string) ?? null
  const title = (card.get('title')?.valueOf() as string) ?? null
  const role = (card.get('role')?.valueOf() as string) ?? null
  const address =
    convertToVCardItem(card.get('adr'))?.map(
      (item): VCardNamedItem => ({
        ...item,
        value: item.value.replaceAll(';', ' ').trim(),
      }),
    ) ?? null
  const url = convertToVCardItem(card.get('url'))

  return { fn, birthday, photo, email, phone, org, title, role, address, url }
}
