import type { ContactItemType } from '@src/service/transport/contacts'

export const defaultNames: { [key in ContactItemType]: string } = {
  address: 'Address',
  'multi-select': 'Tags',
  boolean: 'Checkbox',
  company: 'Company',
  'phone-number': 'Phone',
  date: 'Date',
  email: 'Email',
  number: 'Number',
  role: 'Role',
  string: 'Text',
  url: 'URL',
  'created-by': 'Creator',
  access: 'Access',
}

export const defaultPlaceholders: { [key in ContactItemType]: string | null } = {
  address: 'Set an address...',
  'multi-select': 'Set tags...',
  boolean: null,
  company: 'Set company...',
  'phone-number': 'Set a number...',
  date: 'Set a date...',
  email: 'Set an email...',
  number: 'Set a number...',
  role: 'Set a role',
  string: 'Set a text...',
  url: 'Set a URL...',
  'created-by': '',
  access: '',
}
