import type { E164Number } from 'libphonenumber-js'
import { useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'

import Input from '@src/app/contact/ItemEditor/components/Input'
import { useEditor, useBackdropClick } from '@src/app/contact/ItemEditor/context'
import keyboard from '@src/lib/keyboard'

export interface EditPhoneNumberProps {
  defaultValue: string
  onSave: (number: string) => void
}

const EditPhoneNumber = function ({ defaultValue, onSave }: EditPhoneNumberProps) {
  const [, hide] = useEditor()
  const [value, setValue] = useState<E164Number | undefined>(defaultValue as E164Number)

  const handleSave = () => {
    if (!value) {
      return
    }

    onSave(value)
    hide()
  }

  useBackdropClick(handleSave, [value])

  return (
    <PhoneInput
      autoFocus
      type="text"
      defaultCountry="US"
      placeholder="e.g. (123) 456-7890"
      value={value}
      onChange={setValue}
      onKeyDown={keyboard.onEnterOrTab(handleSave)}
      inputComponent={Input as (props) => JSX.Element}
    />
  )
}

export default EditPhoneNumber
