import dayjs from 'dayjs'
import type { Table } from 'dexie'
import { makeAutoObservable, runInAction } from 'mobx'

import type CodableTag from '@src/service/model/CodableTag'
import makePersistable from '@src/service/storage/makePersistable'
import type { LabelDelete, LabelUpdate } from '@src/service/transport/websocket'
import { TAG_TABLE_NAME } from '@src/service/worker/repository/TagRepository'

import type Service from '..'

export default class TagStore {
  private readonly table: Table<CodableTag>
  private lastFetchedAt: Record<string, number | null> = {}

  constructor(private root: Service) {
    this.table = root.storage.database.table(TAG_TABLE_NAME)
    this.handleWebsocket()

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )

    makePersistable<this, 'lastFetchedAt'>(this, 'TagStore', {
      lastFetchedAt: root.storage.sync(),
    })
  }

  load() {
    return this.table.toArray()
  }

  get(tagId: string) {
    return this.table.get(tagId)
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'label-update':
          void this.handleLabelUpdate(msg)
          break
        case 'label-delete':
          void this.handleLabelDelete(msg)
          break
      }
    })
  }

  private async handleLabelUpdate({ label }: LabelUpdate) {
    const tag = await this.table.get(label.id)
    if (tag) {
      await this.table.put({ ...tag, ...label })
    } else {
      const newTag = {
        ...label,
        source: 'AI',
        color: null,
        callsLabeled: 0,
        userId: null,
        createdAt: new Date().toISOString(),
        deletedAt: null,
      }
      await this.table.put(newTag)
    }
  }

  private handleLabelDelete({ id }: LabelDelete) {
    void this.table.delete(id)
  }

  async getByPhoneNumberId(phoneNumberId: string) {
    const lastFetched = this.lastFetchedAt[phoneNumberId]
    const tags: CodableTag[] = []
    let pageToken: string | undefined

    do {
      const response = await this.root.transport.communication.tags.listByPhoneNumberId({
        phoneNumberId,
        query: {
          since: lastFetched ? dayjs(lastFetched).toDate() : undefined,
          includeCallsLabeled: true,
          pageToken,
        },
      })
      tags.push(...response.data)
      pageToken = response.nextPageToken
    } while (pageToken)

    await this.table.bulkPut(tags)
    await this.syncDeletedTags(phoneNumberId)

    runInAction(() => {
      const latestTagTimestamp =
        tags.length > 0
          ? Math.max(...tags.map((tag) => dayjs(tag.updatedAt).valueOf()))
          : 0

      this.lastFetchedAt[phoneNumberId] = Math.max(
        latestTagTimestamp,
        this.lastFetchedAt[phoneNumberId] || 0,
      )
    })
  }

  private async syncDeletedTags(phoneNumberId: string) {
    const deletedTags = await this.fetchDeletedTags(phoneNumberId)

    if (deletedTags.length === 0) {
      return
    }

    const deletedTagIds = deletedTags.map((tag) => tag.id)
    await this.table.bulkDelete(deletedTagIds)
  }

  async fetchDeletedTags(phoneNumberId: string) {
    const lastFetched = this.lastFetchedAt[phoneNumberId]

    const deletedTags: CodableTag[] = []
    let pageToken: string | undefined

    do {
      const response = await this.root.transport.communication.tags.fetchDeletedTags({
        phoneNumberId,
        query: {
          since: lastFetched ? dayjs(lastFetched).toDate() : undefined,
          pageToken,
        },
      })
      deletedTags.push(...response.data)
      pageToken = response.nextPageToken
    } while (pageToken)

    return deletedTags
  }

  async create(
    tag: Pick<CodableTag, 'name' | 'description' | 'emoji' | 'phoneNumberId'>,
  ) {
    const response = await this.root.transport.communication.tags.create(tag)
    await this.table.put({ ...response, callsLabeled: 0 })
  }

  async update(tag: Pick<CodableTag, 'id' | 'name' | 'description' | 'emoji'>) {
    const existingTag = await this.table.get(tag.id)
    const response = await this.root.transport.communication.tags.update(tag)
    await this.table.put({ ...existingTag, ...response })
  }

  async toggle(tagId: string, isEnabled: boolean) {
    await this.root.transport.communication.tags.toggle(tagId, isEnabled)
    const tag = await this.table.get(tagId)
    if (tag) {
      tag.archivedAt = isEnabled ? null : new Date().toISOString()
      await this.table.put(tag)
    }
  }

  async deleteTagFromActivity({
    tagId,
    activityId,
  }: {
    tagId: string
    activityId: string
  }) {
    const tag = await this.table.get(tagId)
    const activity = this.root.activity.get(activityId)

    if (!tag || !activity) {
      return
    }

    const liteTagIndexToRemove = activity.tags.findIndex(
      (liteTag) => liteTag.id === tag.id,
    )

    if (liteTagIndexToRemove === -1) {
      return
    }

    const liteTag = activity.tags[liteTagIndexToRemove]
    activity.tags.splice(liteTagIndexToRemove, 1)

    try {
      await this.root.transport.communication.tags.deleteTagFromActivity({
        activityId,
        tagId,
      })
    } catch (error) {
      // @ts-expect-error unchecked index access
      activity.tags.splice(liteTagIndexToRemove, 0, liteTag)
      throw error
    }
  }

  async delete(tagId: string) {
    const tag = await this.table.get(tagId)

    if (!tag) {
      return
    }

    await this.table.delete(tagId)
    try {
      await this.root.transport.communication.tags.delete(tagId)
    } catch (error) {
      await this.table.put(tag)
      throw error
    }
  }
}
