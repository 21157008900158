import config from '@src/config'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface PromptSettings {
  id: string
  version: number
  name: string
  aiProviderId: string
  phoneNumberId: string
  useCase: string
  isActive: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

type AgentResourceDataItem = {
  storageBucket: string
  storageKey: string
}

type AgentResourceContentItem = {
  content: string
}

export interface AgentResource {
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
  createdBy: string
  updatedBy: string
  deletedBy?: string
  id: string
  data: AgentResourceDataItem | AgentResourceContentItem
  mimeType: string
  filename: string
  title: string
  emoji?: string
}

export default class AiClient {
  constructor(private transport: Transport) {}
  automations = {
    getResourceById: (id: string): Promise<AgentResource> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/${id}`,
          method: 'GET',
        }),
      )
    },
    getResources: (): Promise<{
      data: AgentResource[]
      totalItems: number
      nextPageToken: string
    }> => {
      const params = new URLSearchParams({
        maxResults: '1000',
      })

      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources?${params.toString()}`,
          method: 'GET',
        }),
      )
    },
    createResourcePage: (
      title: string,
      content: string,
    ): Promise<{
      id: string
      version: number
    }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/text`,
          method: 'POST',
          body: {
            title,
            content,
          },
        }),
      )
    },
    updateResourcePage: (
      id: string,
      title: string,
      content: string,
    ): Promise<{
      id: string
      version: number
    }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/text/${id}`,
          method: 'PUT',
          body: {
            title,
            content,
          },
        }),
      )
    },
    deleteResource: (id: string) => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/${id}`,
          method: 'DELETE',
        }),
      )
    },
  }
  prompt = {
    toggle: (promptId: string, isActive: boolean): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/${promptId}/toggle`,
          method: 'PUT',
          body: {
            isActive,
          },
        }),
      )
    },

    get: (phoneNumberId: string): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/phone-number/${phoneNumberId}`,
          method: 'GET',
        }),
      )
    },
  }
}
