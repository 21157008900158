import { useState, useEffect } from 'react'
import type { DayPickerProps } from 'react-day-picker'
import { DayPicker } from 'react-day-picker'

import { useEditor, useBackdropClick } from '@src/app/contact/ItemEditor/context'
import { DangerButton } from '@src/component/button'
import { parseFromString } from '@src/lib'
import useInputState from '@src/lib/hooks/useInputState'
import keyboard from '@src/lib/keyboard'
import TextInput from '@ui/TextInput'
import { useTheme } from '@ui/theme'

import * as styles from './EditDate.css'

export interface EditDateProps {
  defaultValue: Date | null
  clear?: boolean
  onSave: (date: Date | null) => void
}

const EditDate = function ({ clear, defaultValue, onSave }: EditDateProps) {
  const theme = useTheme()
  const [date, setDate] = useState(defaultValue || undefined)
  const [input, setInput] = useInputState('')
  const [, hide] = useEditor()

  const handleDayClick = (date: Date) => {
    onSave(date)
    hide()
  }

  const handleSave = () => {
    if (date) {
      onSave(date)
      hide()
    }
  }

  const handleClickOutside = () => {
    if (date) {
      onSave(date)
    }
    hide()
  }

  const handleClear = () => {
    onSave(null)
    hide()
  }

  useEffect(() => {
    const date = parseFromString(input, { forwardDate: true })
    if (date) {
      setDate(date)
    }
  }, [input])

  useBackdropClick(handleClickOutside, [])

  const dayPickerClassNames: DayPickerProps['classNames'] = {
    root: styles.dayPickerRoot,

    month_caption: styles.caption,
    button_previous: styles.navButtonPrev,
    button_next: styles.navButtonNext,

    months: styles.months,
    month: styles.month,

    month_grid: styles.table,
    weekday: styles.headCell,

    week_number: styles.weekNumber,
    day: styles.dayCell,
    day_button: theme.match({
      light: styles.dayLight,
      dark: styles.dayDark,
    }),
    selected: styles.daySelected,
    today: styles.dayToday,
    disabled: styles.disabled,
    outside: theme.match({
      light: styles.dayOutsideLight,
      dark: styles.dayOutsideDark,
    }),

    footer: styles.footer,
  }

  return (
    <div className={styles.root}>
      <TextInput
        size={35}
        autoFocus
        placeholder="Type today, in 5 days, etc"
        value={input}
        onChange={setInput}
        className={styles.input}
        onKeyDown={keyboard.onEnter(handleSave)}
      />
      <DayPicker
        showOutsideDays
        onDayClick={handleDayClick}
        selected={date}
        defaultMonth={date || new Date()}
        classNames={dayPickerClassNames}
        modifiers={{
          selected: date,
        }}
      />
      {clear && (
        <DangerButton
          style={{ margin: 10 }}
          onClick={handleClear}
          variant="outlined"
          color="primary"
          size="small"
        >
          Clear
        </DangerButton>
      )}
    </div>
  )
}

export default EditDate
