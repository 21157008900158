import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import Input from '@src/app/contact/ItemEditor/components/Input'
import { List, ListItem } from '@src/app/contact/ItemEditor/components/List'
import { useBackdropClick, useEditor } from '@src/app/contact/ItemEditor/context'
import { useAppStore } from '@src/app/context'
import useInputState from '@src/lib/hooks/useInputState'
import useKeyStepper from '@src/lib/hooks/useKeyStepper'
import { logError } from '@src/lib/log'
import Typography from '@ui/Typography'

export interface EditCompanyProps {
  defaultValue: string
  onSave: (name: string) => void
}

const EditCompany = function ({ defaultValue, onSave }: EditCompanyProps) {
  const [, hide] = useEditor()
  const { service } = useAppStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const [input, setInput] = useInputState(defaultValue)
  const [names, setNames] = useState<(string | null)[]>([])

  const handleSave = () => {
    onSave(input)
    hide()
  }

  useBackdropClick(handleSave, [input])

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  const { getItemProps, selectedIndex } = useKeyStepper({
    items: names,
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    node: rootRef.current ?? undefined,
    defaultSelectedIndex: 0,
    name: 'contact/editor/company',
    handleSelect: (item) => {
      if (item === null) {
        onSave(input)
      } else {
        onSave(item)
      }
      hide()
    },
  })

  useEffect(() => {
    service.search
      .companies(input, 5)
      .then((names) => {
        if (names.includes(input) || !input) {
          setNames(names)
        } else {
          setNames([null, ...names])
        }
      })
      .catch(logError)
  }, [input, service.search])

  return (
    <div ref={rootRef}>
      <Input
        autoFocus
        type="text"
        value={input}
        placeholder="Enter a name"
        onChange={setInput}
      />
      {names.length > 0 && (
        <List>
          {names.map((name, index) => (
            <ListItem
              key={name}
              isSelected={selectedIndex === index}
              {...getItemProps(index)}
            >
              {name === null ? (
                <Typography nowrap color="textPrimary" variant="footnote">
                  <Typography component="span" color="textSecondary" variant="inherit">
                    Set to
                  </Typography>{' '}
                  <b>{input}</b>
                </Typography>
              ) : (
                <Typography nowrap color="textPrimary" variant="footnote">
                  {name}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default observer(EditCompany)
