import Input from '@src/app/contact/ItemEditor/components/Input'
import { useEditor, useBackdropClick } from '@src/app/contact/ItemEditor/context'
import useInputState from '@src/lib/hooks/useInputState'
import keyboard from '@src/lib/keyboard'

export interface EditStringItemProps {
  defaultValue: string
  placeholder: string
  onSave: (name: string) => void
}

const EditStringItem = function ({
  defaultValue,
  placeholder,
  onSave,
}: EditStringItemProps) {
  const [, hide] = useEditor()
  const [input, setInput] = useInputState(defaultValue || '')

  const handleSave = () => {
    onSave(input)
    hide()
  }

  useBackdropClick(handleSave, [input])

  return (
    <Input
      autoFocus
      type="text"
      value={input}
      placeholder={placeholder}
      onChange={setInput}
      onKeyDown={keyboard.onEnterOrTab(handleSave)}
    />
  )
}

export default EditStringItem
