import type { TemplateTagItemOption } from '@src/lib/search'
import type { ContactTemplateItemModel } from '@src/service/model'
import { colors } from '@src/theme'

const colorForOption = (name: string, templateItem: ContactTemplateItemModel): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const option = Array.isArray(templateItem.options)
    ? templateItem.options.find((opt: TemplateTagItemOption) => opt.name === name)
    : undefined
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return option?.color ?? colors.veryLightGray
}

export default colorForOption
