/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { forwardRef } from 'react'

import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'

import * as styles from './ItemValue.css'

export interface ItemValueProps {
  placeholder?: string
  value: React.ReactNode
  strikethrough?: boolean
  tooltipProps?: {
    title: string
    body?: string
  }
  alwaysShowTitle?: boolean
  actions?: React.ReactNode
  className?: string
  onClick?: () => void
}

const ItemValue: React.ForwardRefRenderFunction<HTMLDivElement, ItemValueProps> =
  function (
    {
      actions,
      value,
      className,
      placeholder,
      strikethrough,
      tooltipProps,
      alwaysShowTitle,
      onClick,
    },
    outerRef,
  ) {
    const hasValue = Array.isArray(value)
      ? value.length > 0
      : value !== '' && value != null
    const { isOverflowing, ref } = useIsOverflowing()

    const tooltipTitle = (() => {
      if (tooltipProps) {
        return tooltipProps.title
      } else if (typeof value === 'string') {
        return value
      } else {
        return ''
      }
    })()

    const Element = onClick ? 'button' : 'div'

    return (
      <Tooltip
        title={tooltipTitle}
        body={tooltipProps?.body}
        disabled={!hasValue || (!alwaysShowTitle && !isOverflowing)}
      >
        <div className={styles.wrapper({ clickable: !!onClick })} ref={outerRef}>
          <Element
            onClick={onClick}
            className={cx(styles.root({ clickable: !!onClick }), className)}
          >
            {hasValue ? (
              <Typography
                variant="footnote"
                color="textPrimary"
                className={cx(styles.value({ strikethrough }), styles.wrap)}
                ref={ref}
              >
                {value}
              </Typography>
            ) : (
              <Typography
                nowrap
                variant="footnote"
                color="textTertiary"
                className={styles.value()}
              >
                {placeholder}
              </Typography>
            )}
          </Element>
          {actions && hasValue && <div className={styles.actions}>{actions}</div>}
        </div>
      </Tooltip>
    )
  }

export default observer(forwardRef(ItemValue))
