import { useContext } from 'react'
import { useMatch } from 'react-router-dom'

import { useAppStore } from '@src/app/context'

import OpenInboxContext from './OpenInboxContext'

/**
 * Hook intended for use in components that need access to the currently open
 * inbox, if any.
 *
 * Example components: AppCommand
 */
export default function useOpenInbox() {
  const contextValue = useContext(OpenInboxContext)
  const patternMatch = useMatch('/inbox/:inboxId/*')
  const app = useAppStore()

  if (contextValue) {
    return contextValue
  }

  if (patternMatch?.params.inboxId) {
    const inbox = app.inboxes.all.get(patternMatch.params.inboxId)
    return inbox
  }

  return null
}

/**
 * Hook intended for use in components that expect an inbox to be open.
 *
 * Example components: InboxView, ConversationList, ConversationHeader
 */
export function useOpenInboxOrThrow() {
  const inbox = useOpenInbox()

  if (!inbox) {
    throw new Error(
      `Expected an open inbox, but none was found. The useOpenInboxOrThrow() hook should only be used in components that expect an inbox to be open.`,
    )
  }

  return inbox
}

/**
 * Hook intended for use in components that need access to the currently open inbox
 * or fallback to the default inbox if no inbox is open.
 *
 * Example components: ContactHeader, ReadOnlyItem, CustomItem
 *
 * @returns The currently open inbox, or the default inbox if no inbox is open.
 */
export function useOpenInboxOrDefault() {
  const inbox = useOpenInbox()
  const app = useAppStore()

  if (!inbox) {
    return app.inboxes.defaultSelected
  }

  return inbox
}
