import ClearIcon from '@material-ui/icons/Clear'
import { observer } from 'mobx-react-lite'

import TextWrap from '@ui/TextWrap'
import Typography from '@ui/Typography'

import * as styles from './Tag.css'

interface TagProps {
  name: string
  color: string
  onDelete?: () => void
}

const Tag = function ({ name, color, onDelete }: TagProps) {
  return (
    <div className={styles.root}>
      <i className={styles.icon} style={{ backgroundColor: color }} />
      <Typography variant="caption1" className={styles.label}>
        <TextWrap value={name}>{name}</TextWrap>
      </Typography>
      {onDelete && (
        <ClearIcon fontSize="small" className={styles.clearIcon} onClick={onDelete} />
      )}
    </div>
  )
}

export default observer(Tag)
