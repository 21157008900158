import { assignInlineVars } from '@vanilla-extract/dynamic'
import { observer } from 'mobx-react-lite'
import { useReducer } from 'react'

import Popover from '@ui/Popover'
import type { Sensor } from '@ui/SensorProvider'

import * as styles from './ItemEditor.css'
import {
  EditCompany,
  EditDate,
  EditName,
  EditPhoneNumber,
  EditStringItem,
  EditTags,
} from './components'
import {
  ContactEditorDispatchContext,
  ContactEditorStateContext,
  reducer,
} from './context'

const ItemEditor = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    backdropClicked: false,
    targetRef: null,
    command: null,
  })

  const onClose = (sensor: Sensor) => {
    if (sensor.type === 'keyboard' && sensor.key === 'Escape') {
      state.command?.onDiscard?.()
      dispatch({ type: 'hide editor' })
      return
    }

    dispatch({ type: 'backdrop clicked' })
  }

  let width = (state.targetRef?.current?.getBoundingClientRect().width ?? 0) + 10 || 210
  if (state.command?.name === 'edit date') {
    width = Math.max(250, width)
  }

  return (
    <ContactEditorStateContext.Provider value={state}>
      <ContactEditorDispatchContext.Provider value={dispatch}>
        {state.open && (
          <Popover
            targetRef={state.targetRef}
            onClose={onClose}
            className={styles.popover}
            isDismissable
            autoFocus
            style={assignInlineVars({
              [styles.widthVar]: `${width}px`,
            })}
            offset={-32}
          >
            {state.command?.name === 'edit name' ? (
              <EditName {...state.command} />
            ) : state.command?.name === 'edit phone number' ? (
              <EditPhoneNumber {...state.command} />
            ) : state.command?.name === 'edit string' ? (
              <EditStringItem {...state.command} />
            ) : state.command?.name === 'edit company' ? (
              <EditCompany {...state.command} />
            ) : state.command?.name === 'edit tags' ? (
              <EditTags {...state.command} />
            ) : state.command?.name === 'edit date' ? (
              <EditDate {...state.command} />
            ) : null}
          </Popover>
        )}
        {children}
      </ContactEditorDispatchContext.Provider>
    </ContactEditorStateContext.Provider>
  )
}

export default observer(ItemEditor)
