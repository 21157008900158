/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { useEditor } from '@src/app/contact/ItemEditor'
import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import type { ContactItemType } from '@src/service/transport/contacts'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import {
  AddressIcon,
  AtIcon,
  ChecboxIcon,
  CompanyIcon,
  DateIcon,
  MultiSelectIcon,
  NumberIcon,
  PhoneIcon,
  RoleIcon,
  TextIcon,
  URLIcon,
} from '@ui/icons/tint/16/contact-info'
import { ProfileIcon, ShowIcon } from '@ui/icons/tint/16/general'
import { DragIndicatorIcon } from '@ui/icons/tint/misc'
import { HStack } from '@ui/layout/Stack'

import type { ItemValueProps } from './ItemValue'
import ItemValue from './ItemValue'
import * as styles from './index.css'

type BaseItemProps = {
  type: ContactItemType | null
  name: string
  draggable?: boolean
  isDragging?: boolean
  valueRef?: React.Ref<HTMLDivElement>
  valueStrikethrough?: ItemValueProps['strikethrough']
  valueTooltipProps?: ItemValueProps['tooltipProps']
  onValueClick?: ItemValueProps['onClick']
  alwaysShowValueTitle?: ItemValueProps['alwaysShowTitle']
} & Pick<ItemValueProps, 'placeholder' | 'value' | 'actions' | 'className'>

interface ReadonlyItemProps extends BaseItemProps {
  disableName: true
  onNameChange?: (name: string) => void
  onDelete?: () => void
}

interface EditableItemProps extends BaseItemProps {
  disableName?: false
  onNameChange: (name: string) => void
  onDelete: () => void
}

export type ItemProps = ReadonlyItemProps | EditableItemProps

export interface ContactItemMethod {
  changeName: () => void
}

const Item: React.ForwardRefRenderFunction<ContactItemMethod, ItemProps> = function (
  {
    actions,
    type,
    name,
    value,
    valueStrikethrough,
    valueTooltipProps,
    alwaysShowValueTitle,
    disableName,
    valueRef,
    placeholder,
    draggable,
    isDragging,
    onNameChange,
    onValueClick,
    onDelete,
  },
  ref,
) {
  const [editor] = useEditor()
  const nameRef = useRef<HTMLButtonElement>(null)
  const { isOverflowing, ref: innerNameRef } = useIsOverflowing<HTMLDivElement>()

  const handleEditName = () => {
    if (disableName || !nameRef.current) {
      return
    }
    editor(nameRef, {
      name: 'edit name',
      defaultValue: name,
      onSave: onNameChange,
      onDelete: onDelete,
    })
  }

  useImperativeHandle(ref, () => ({
    changeName: handleEditName,
  }))

  const Icon = type && icons[type]
  const disableTooltip = disableName && !isOverflowing

  return (
    <div className={styles.root}>
      {draggable && (
        <HStack gap={2} className={styles.drag} justifyContent="center">
          <DragIndicatorIcon />
          <DragIndicatorIcon />
        </HStack>
      )}
      <Tooltip
        disabled={disableTooltip}
        title={isOverflowing ? name : disableName ? '' : 'Edit name'}
        body={isOverflowing && !disableName ? 'Click to edit name' : undefined}
        placement="left"
        align="center"
        allowDragging
        isHidden={isDragging}
        offset={18}
      >
        <div className={styles.nameWrapper}>
          <button
            ref={nameRef}
            className={cx(styles.name, { [styles.editableName]: !disableName })}
            disabled={disableName}
            onClick={handleEditName}
          >
            {Icon && <Icon className={styles.icon} />}
            <Typography
              nowrap
              variant="footnote"
              color="textSecondary"
              className={styles.nameText}
              ref={innerNameRef}
            >
              {name}
            </Typography>
          </button>
        </div>
      </Tooltip>
      <ItemValue
        ref={valueRef}
        onClick={onValueClick}
        value={value}
        strikethrough={valueStrikethrough}
        tooltipProps={valueTooltipProps}
        alwaysShowTitle={alwaysShowValueTitle}
        placeholder={placeholder}
        actions={actions}
      />
    </div>
  )
}

export default observer(forwardRef(Item))

const icons: { [key in ContactItemType]: React.FC<React.SVGAttributes<SVGElement>> } = {
  address: AddressIcon,
  boolean: ChecboxIcon,
  company: CompanyIcon,
  date: DateIcon,
  email: AtIcon,
  'multi-select': MultiSelectIcon,
  number: NumberIcon,
  'phone-number': PhoneIcon,
  role: RoleIcon,
  string: TextIcon,
  url: URLIcon,
  'created-by': ProfileIcon,
  access: ShowIcon,
}

export function isValueChanged(value1: any, value2: any): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  return value1 != value2 && (value1 || value2)
}
