import { makeAutoObservable } from 'mobx'

import type IUiStore from '@src/app/IUiStore'
import type InboxesUiStore from '@src/app/inbox/InboxesUiStore'
import StatefulPromise from '@src/lib/StatefulPromise'
import { DisposeBag } from '@src/lib/dispose'
import { logError } from '@src/lib/log'
import type AiStore from '@src/service/AiStore/AiStore'
import Collection from '@src/service/collections/Collection'
import type Transport from '@src/service/transport'
import type { AgentResource } from '@src/service/transport/AiClient'
import type UserStore from '@src/service/user-store'

export default class AiKnowledgeUiStore implements IUiStore {
  resources = new Collection<AgentResource>()
  private disposeBag = new DisposeBag()

  private async handleFetchResources() {
    const { data } = await this.aiStore.automations.getResources()
    this.resources.putBulk(data)
    return data
  }

  private fetchResourcesPromise = new StatefulPromise(
    this.handleFetchResources.bind(this),
  )

  private getResources() {
    return this.fetchResourcesPromise.run()
  }

  constructor(
    private aiStore: AiStore,
    private transport: Transport,
    private inboxes: InboxesUiStore,
    private user: UserStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true })

    void this.getResources().catch((e) => {
      logError(e)
    })
    this.handleSocketEvents()
  }

  get canUserAccessAiAutomations() {
    const member = this.user.getCurrentUser().asMember
    if (member?.isAdmin) {
      return true
    }
    return this.inboxes?.all.list.some((inbox) => {
      if (!inbox.phoneNumber) {
        return false
      }
      return member?.canAdminPhoneNumber(inbox.phoneNumber)
    })
  }

  get textResources() {
    return this.resources.list.filter((resource) => resource.mimeType === 'text/plain')
  }

  get uploadResources() {
    return this.resources.list.filter((resource) => resource.mimeType !== 'text/plain')
  }

  getResourceById(id: string) {
    return this.aiStore.automations.getResourceById(id)
  }

  async createPageResource(title: string, content: string, userId: string) {
    const createdResource = await this.aiStore.automations.createResourcePage(
      title,
      content,
    )
    const resource: AgentResource = {
      ...createdResource,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: userId,
      updatedBy: userId,
      filename: title,
      mimeType: 'text/plain',
      title,
      data: { content },
    }
    return this.resources.put(resource)
  }

  async updatePageResource(id: string, title: string, content: string, userId: string) {
    const currentResource = this.resources.get(id)
    try {
      const updatedResource = await this.aiStore.automations.updateResourcePage(
        id,
        title,
        content,
      )
      const resource = {
        ...currentResource,
        ...updatedResource,
        createdAt: currentResource?.createdAt || new Date().toISOString(),
        createdBy: currentResource?.createdBy || userId,
        updatedAt: new Date().toISOString(),
        updatedBy: userId,
        filename: title,
        mimeType: 'text/plain',
        title,
        data: { content },
      } satisfies AgentResource
      return this.resources.put(resource)
    } catch (e) {
      if (currentResource) {
        this.resources.put(currentResource)
      }
      throw e
    }
  }

  async deleteResource(id: string) {
    await this.aiStore.automations.deleteResource(id)
    return this.resources.delete(id)
  }

  private handleSocketEvents() {
    const subscription = this.transport.onNotificationData.subscribe((event) => {
      switch (event.type) {
        case 'agent-resource-definition-create': {
          return this.resources.put(event.definition)
        }
        case 'agent-resource-definition-update': {
          return this.resources.put(event.definition)
        }
        case 'agent-resource-definition-delete': {
          return this.resources.delete(event.id)
        }
      }
    })

    this.disposeBag.add(subscription)
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
