import { useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { List, ListItem } from '@src/app/contact/ItemEditor/components/List'
import useKeyStepper from '@src/lib/hooks/useKeyStepper'
import Typography from '@ui/Typography'

import * as styles from './ColorPicker.css'

interface ColorPickerProps {
  onSelect: (color: string) => void
}

const ColorPicker = function ({ onSelect }: ColorPickerProps) {
  const theme = useTheme()

  const colors = useMemo(() => {
    return [
      { color: theme.palette.op.contactTags.green1, name: 'Green' },
      { color: theme.palette.op.contactTags.red1, name: 'Red' },
      { color: theme.palette.op.contactTags.blue1, name: 'Blue' },
      { color: theme.palette.op.contactTags.orange1, name: 'Orange' },
      { color: theme.palette.op.contactTags.cherokee1, name: 'Cherokee' },
      { color: theme.palette.op.contactTags.turquoise1, name: 'Turquoise' },
      { color: theme.palette.op.contactTags.mauve1, name: 'Mauve' },
      { color: theme.palette.op.contactTags.purple1, name: 'Purple' },
      { color: theme.palette.op.contactTags.monaLisa1, name: 'Mona Lisa' },
      { color: theme.palette.op.contactTags.gray1, name: 'Gray' },
    ]
  }, [theme])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: colors,
    defaultSelectedIndex: 0,
    name: 'contact/editor/tags',
    handleSelect: (item) => {
      if (item) {
        onSelect(item.color)
      }
    },
  })

  return (
    // Since the ListItems themselves aren't focusable, this focus trap
    // allows arrow keys to select list items, otherwise focus remains
    // on the button underneath the popover and arrow keys won't work
    <div tabIndex={0} className={styles.focusTrap}>
      <List>
        {colors.map((color, index) => (
          <ListItem
            className={styles.row}
            isSelected={selectedIndex === index}
            key={color.color}
            {...getItemProps(index)}
          >
            <i className={styles.color} style={{ backgroundColor: color.color }} />
            <Typography component="span" variant="footnote" color="textPrimary" nowrap>
              {color.name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default observer(ColorPicker)
