import cx from 'classnames'

import * as styles from './List.css'

interface ListProps {
  children: React.ReactNode
}

const List = function ({ children }: ListProps) {
  return <div className={styles.root}>{children}</div>
}

interface ListItemProps extends React.HTMLProps<HTMLDivElement> {
  isSelected: boolean
}

const ListItem = function ({ isSelected, children, className, ...props }: ListItemProps) {
  return (
    <div {...props} className={cx(styles.item({ selected: isSelected }), className)}>
      {children}
    </div>
  )
}

export { List, ListItem }
