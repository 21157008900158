import * as LDClient from 'launchdarkly-js-client-sdk'
import isEqual from 'lodash/fp/isEqual'
import { action, makeObservable, observable } from 'mobx'

import config, { isWeb } from '@src/config'
import type Service from '@src/service'
import type { OrganizationModel, SubscriptionModel, UserModel } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'

import type { FlagKey, FlagValue, Flags } from './FlagsTypes'
import defaultFlags from './defaultFlags'

const staticAnonymousUser: LDClient.LDSingleKindContext = {
  kind: 'anonUser',
  key: localStorage.getItem('anon_user_key') ?? undefined,
  anonymous: true,
}

type Custom = NonNullable<
  Record<string, string | boolean | number | (string | boolean | number)[]>
>

export interface IFlagsService {
  flags: Flags
  getFlag<Key extends FlagKey>(key: Key, defaultValue?: FlagValue<Key>): FlagValue<Key>
  identify(
    user: UserModel,
    organization: OrganizationModel,
    subscription: SubscriptionModel,
    workspaceSize: number,
  ): Promise<void>
  waitUntilReady(): Promise<void>
  tearDown(): Promise<void>
}

export default class FlagsService implements IFlagsService {
  private ldClient: LDClient.LDClient

  flags: Flags = defaultFlags

  constructor(private readonly root: Service) {
    makeObservable<this, 'syncFlags' | 'handleChange'>(this, {
      flags: observable,
      syncFlags: action.bound,
      handleChange: action.bound,
    })

    makePersistable(this, 'FlagsService', {
      flags: this.root.storage.async(),
    })

    this.ldClient = LDClient.initialize(
      config.LAUNCH_DARKLY_CLIENT_SIDE_ID,
      staticAnonymousUser,
      {},
    )

    this.ldClient.on('change', this.handleChange.bind(this))
  }

  getFlag<Key extends FlagKey>(key: Key, defaultValue?: FlagValue<Key>): FlagValue<Key> {
    const isDefinedFlag = (flag: Flags[Key]): flag is FlagValue<Key> => flag !== undefined
    const flag = this.flags[key]

    if (isDefinedFlag(flag)) {
      return flag
    }

    return defaultValue ?? defaultFlags[key]
  }

  async tearDown() {
    await this.ldClient.close()
  }

  async waitUntilReady() {
    await this.ldClient.waitUntilReady()
    this.syncFlags()
  }

  async identify(
    user: UserModel,
    organization: OrganizationModel,
    subscription: SubscriptionModel,
    workspaceSize: number,
  ) {
    const custom: Custom = {
      appVersion: config.VERSION,
      organizationId: organization.id,
      organizationCreatedAt: new Date(organization.createdAt ?? 0).toISOString(),
    }

    if (subscription.type) {
      custom.subscriptionType = subscription.type
    }

    await this.ldClient.identify({
      kind: 'multi',
      user: {
        key: user.id,
        anonymous: false,
        avatar: user.pictureUrl ?? '',
        email: user.email ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        createdAt: user.createdAt ?? '',
        ...custom,
      },
      anonUser: staticAnonymousUser,
      device: {
        key: user.id + '_' + (isWeb ? 'web' : 'desktop'),
        platform: isWeb ? 'web' : 'desktop',
      },
      organization: {
        key: organization.id,
        subscriptionType: subscription.type,
        memberCount: workspaceSize,
      },
    })
  }

  // if the flag value changes and the current user is not affected,
  // the current & previous values for the flag will be the same
  private handleChange(updatedFlags: LDClient.LDFlagChangeset) {
    for (const [key, value] of Object.entries(updatedFlags)) {
      if (this.flags[key] !== undefined && !isEqual(this.flags[key], value.current)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.flags[key] = value.current
      }
    }
  }

  private syncFlags() {
    const newFlags = this.ldClient.allFlags()

    for (const [key, value] of Object.entries(this.flags)) {
      if (newFlags[key] !== undefined && !isEqual(newFlags[key], value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.flags[key] = newFlags[key]
      }
    }
  }
}
