import cx from 'classnames'

import * as styles from './Bone.css'

export interface BoneProps {
  className?: string
  style?: React.CSSProperties
  'data-testid'?: string
}

const Bone = ({ className, style, ...rest }: BoneProps) => {
  return <div className={cx(styles.root, className)} style={style} {...rest} />
}

export default Bone
